<template>
  <div class="w-full mb-5">
    <h1 class="text-2xl font-bold">
      {{ $t("business.settings.payout_option_title") }}
    </h1>
    <p class="mt-4">
      {{ $t("business.settings.payout_option_description") }}
    </p>
  </div>
  <div class="text-left border bg-white rounded-lg mb-10">
    <div class="px-4 py-6 flex flex-col space-y-8 text-sm">
      <label class="inline-flex items-center">
        <input
          type="radio"
          class="form-radio focus:ring-transparent"
          name="business.payout_option"
          v-model="business.config.payout_type"
          value="daily"
          checked
        />
        <span class="ml-2">{{ $t("business.settings.payout_auto") }}</span>
      </label>
      <label class="inline-flex items-center">
        <input
          type="radio"
          class="form-radio focus:ring-transparent"
          name="business.payout_option"
          v-model="business.config.payout_type"
          value="on_demand"
        />
        <span class="ml-2">{{ $t("business.settings.payout_on_demand") }}</span>
      </label>
    </div>
    <div
      class="px-4 py-2.5 bg-gray-50 rounded-b-lg sm:flex sm:flex-row-reverse"
    >
      <app-button
        @click="savePayoutType()"
        :showf70Icon="false"
        type="button"
        class="sm:ml-3 sm:w-auto"
      >
        {{ $t("general.save") }}
      </app-button>

      <app-button-outline
        type="button"
        class="
          px-4
          py-2
          mt-3
          w-full
          shadow-sm
          bg-white
          rounded-md
          font-medium
          inline-flex
          outline-none
          justify-center
          text-gray-700
          hover:bg-gray-50
          border border-gray-300
          sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
        "
        @click="resetDefaultPayoutType"
      >
        {{ $t("business.settings.reset_default_button") }}
      </app-button-outline>
    </div>
  </div>

  <div class="w-full mb-5">
    <p class="mt-4">
      {{ $t("business.settings.payout_to_description") }}
    </p>
  </div>
  <div class="text-left border bg-white rounded-lg">
    <div class="px-4 py-6 flex flex-col space-y-8 text-sm">
      <!-- Disable first for finalise payout flow for submerchant  -->
      <label class="inline-flex items-center">
        <input
          type="radio"
          :disabled="disablePayout"
          class="form-radio focus:ring-transparent"
          name="business.payout_to"
          v-model="business.config.payout_to"
          value="own"
          checked
        />
        <span class="ml-2">{{
          $t("business.settings.payout_to_sub_merchant")
        }}</span>
      </label>
      <label class="inline-flex items-center">
        <input
          type="radio"
          :disabled="disablePayout"
          class="form-radio focus:ring-transparent"
          name="business.payout_to"
          v-model="business.config.payout_to"
          value="master"
        />
        <span class="ml-2">{{ $t("business.settings.payout_to_master") }}</span>
      </label>
    </div>
    <div
      class="px-4 py-2.5 bg-gray-50 rounded-b-lg sm:flex sm:flex-row-reverse"
    >
      <app-button
        @click="savePayoutTo()"
        :showf70Icon="false"
        :disabled="disablePayout"
        type="button"
        class="sm:ml-3 sm:w-auto"
      >
        {{ $t("general.save") }}
      </app-button>

      <app-button-outline
        type="button"
        :disabled="disablePayout"
        class="
          px-4
          py-2
          mt-3
          w-full
          shadow-sm
          bg-white
          rounded-md
          font-medium
          inline-flex
          outline-none
          justify-center
          text-gray-700
          hover:bg-gray-50
          border border-gray-300
          sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
        "
        @click="resetDefaultPayoutTo"
      >
        {{ $t("business.settings.reset_default_button") }}
      </app-button-outline>
    </div>
  </div>
</template>

<script>
import PAYOUT_TYPE from "@/utils/const/payout_type";
import PAYOUT_TO from "@/utils/const/payout_to";

export default {
  data() {
    return{
      disablePayout: process.env.VUE_APP_DISABLED_PAYOUT_TO_SETTING
    }
  },
  computed: {
    business() {
      return this.$store.getters["businessStore/business"];
    },
  },

  mounted() {
    this.$store.dispatch(
      "businessStore/getBusinessById",
      this.$route.query.business_id
    );
  },

  methods: {
    async savePayoutType() {
      const businessResult = await this.$store.dispatch(
        "businessStore/statusPayout",
        {
          business_id: this.$route.query.business_id,
          payout_status: this.business.config.payout_type,
        }
      );

      if (businessResult != null) {
        this.submitLoading = false;
        this.$notify(
          {
            group: "success",
            title: "Saved!",
          },
          3000
        );
      }
    },

    async resetDefaultPayoutType() {
      this.business.config.payout_type = PAYOUT_TYPE.DAILY;
      this.savePayoutType();
    },

    async savePayoutTo() {
      const businessResult = await this.$store.dispatch(
        "masterBusinessStore/updatePayoutTo",
        {
          business_id: this.business.id,
          master_business_id: this.business.master_business_id,
          payout_to: this.business.config.payout_to,
        }
      );

      if (businessResult != null) {
        this.submitLoading = false;
        this.$notify(
          {
            group: "success",
            title: "Saved!",
          },
          3000
        );
      }
    },

    async resetDefaultPayoutTo() {
      this.business.config.payout_to = PAYOUT_TO.OWN;
      this.savePayoutTo();
    },
  },
};
</script>